body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Copernicus-Book';
  src: local('Copernicus-Book'), url(./fonts/Copernicus-Book.ttf)
}

@font-face {
  font-family: 'Copernicus-Medium';
  src: local('Copernicus-Medium'), url(./fonts/Copernicus-Medium.ttf)
}

@font-face {
  font-family: 'RomanaEF-Book';
  src: local('RomanaEF-Book'), url(./fonts/RomanaEF-Book.otf)
}

@font-face {
  font-family: 'RomanaEF-Light';
  src: local('RomanaEF-Light'), url(./fonts/RomanaEF-Light.otf)
}

@font-face {
  font-family: 'Schnyder S Light';
  src: local('Schnyder S Light'), url(./fonts/Schnyder\ S\ Light.otf)
}

@font-face {
  font-family: 'Schnyder M Light Italic';
  src: local('Schnyder M Light Italic'), url(./fonts/Schnyder\ M\ Light\ Italic.otf)
}

@font-face {
  font-family: 'Schnyder M Demi';
  src: local('Schnyder M Demi'), url(./fonts/Schnyder\ M\ Demi.otf)
}

@font-face {
  font-family: 'Nord-Light';
  src: local('Nord-Light'), url(./fonts/Nord-Light.otf)
}

@font-face {
  font-family: 'Gilmer-Light';
  src: local('Gilmer-Light'), url(./fonts/Gilmer-Light.otf)
}

@font-face {
  font-family: 'GTWalsheimPro-Light';
  src: local('GTWalsheimPro-Light'), url(./fonts/GTWalsheimPro-Light.ttf)
}

@font-face {
  font-family: 'Schnyder M Demi Italic';
  src: local('Schnyder M Demi Italic'), url(./fonts/Schnyder\ M\ Demi\ Italic.otf)
}